import { ref } from 'vue'
import { defineStore } from 'pinia'
import type {
  IFShowModal,
  IRemoveModal, EditUserData, EditTeamData, EditAlertData, EditBusinessData,
  IFRemoveModal, IFEditUserData, IFEditTeamData, IFUpdateAlertData, IFEditMailboxBusinessData, IFEditBusinessData,
  IModal, IModalData, IModalData2, IModalDataMailboxes, IModalDataTeam
} from '@/modules/shared/interfaces/Modal'
import type { IMetricAdjustmentsFront } from '@/modules/settings/interfaces/MetricAdjustments'
import type { IMailboxes } from '@/modules/settings/interfaces/Mailboxes'
import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'

export const useModalStore = defineStore('modal', () => {
  const display = ref<boolean>(false)
  const hasCloseButton = ref<boolean>(true)
  const activeModal = ref<string>('')
  const deleteData = ref<IRemoveModal>({
    type: '',
    title: '',
    text: '',
    data: []
  })

  const modalData = ref<IModalData | IModalData2 | IModalDataMailboxes | IModalDataTeam>()

  const editUserData = ref<EditUserData>({
    id: '',
    name: '',
    email: '',
    role: '',
    mailboxes: [],
    teams: []
  })
  const editTeamData = ref<EditTeamData>({
    departament: '',
    members: []
  })
  const editAlertData = ref<EditAlertData>({
    addresses: [],
    created: '',
    domains: [],
    id: '',
    is_active: true,
    mailboxes: [],
    minutes_gap: 0,
    name: '',
    notify_emails: [],
    notify_webhook: null
  })
  const editMailboxBusinessData = ref<IMailboxes[]>([{
    email: '',
    dataAdded: '',
    dataFrom: '',
    bhsStatus: '',
    bhs: {
      hover: false,
      timezone: '',
      businessHours: []
    },
    status: '',
    name: ''
  }])

  const editBusinessData = ref<EditBusinessData>({
    mailboxes: [],
    type: '',
    timezone: '',
    businessHours: []
  })

  const data = ref < EditBusinessData | IMailboxes[] | IMetricAdjustmentsFront[] | Iitems[] |null >(null)

  return {
    // Properties
    display,
    hasCloseButton,
    activeModal,
    data,
    modalData,
    deleteData,
    editUserData,
    editTeamData,
    editAlertData,
    editBusinessData,
    editMailboxBusinessData,

    // Methods
    openModal: (data: IModal) => {
      activeModal.value = data.name
      modalData.value = data.data
      display.value = true
      hasCloseButton.value = data.hasCloseButton ?? true
    },
    showModalRemove: (data: IFRemoveModal) => {
      activeModal.value = data.name
      deleteData.value = data.data
      display.value = true
      hasCloseButton.value = false
    },
    showModalAdd: (data: IFShowModal) => {
      activeModal.value = data.name
      display.value = true
    },
    showModalUpdateUser: (data: IFEditUserData) => {
      activeModal.value = data.name
      editUserData.value = data.data
      display.value = true
    },
    showModalUpdateTeam: (data: IFEditTeamData) => {
      activeModal.value = data.name
      editTeamData.value = data.data
      display.value = true
    },
    showModalUpdateAlert: (data: IFUpdateAlertData) => {
      activeModal.value = data.name
      editAlertData.value = data.data
      display.value = true
    },
    showModalMailboxBusinessHours: (data: IFEditMailboxBusinessData) => {
      activeModal.value = data.name
      editMailboxBusinessData.value = data.data.mailbox
      display.value = true
    },
    showModalUpdateBusiness: (data: IFEditBusinessData) => {
      activeModal.value = data.name
      editBusinessData.value = data.data
      display.value = true
    },
    showModalUpdateMetricAdjustments: (variables: {name: string, data: { id: number, select1: string, select2: string, text:string, items: Iitems[] }[]}) => {
      activeModal.value = variables.name
      data.value = variables.data
      display.value = true
      hasCloseButton.value = false
    },
    showModalUpdateInternalDomains: (variables: {name: string, data: Iitems[]}) => {
      activeModal.value = variables.name
      data.value = variables.data
      display.value = true
      hasCloseButton.value = false
    },
    hideModal: () => { display.value = false }
  }
})
