import clientsApi from '@/api/clients-api'
import { storeToRefs } from 'pinia'
import parseUserInfo from '@/modules/shared/utils/parser/userInfo'
import { useUserInfoStore } from '@/modules/shared/stores/userInfo'
import { ME } from '@/api/url'

const useUserInfo = () => {
  const store = useUserInfoStore()
  const { userInfo } = storeToRefs(store)

  const getUserInfo = async () => {
    const { data } = await clientsApi.get(ME)
    store.setUserInfo(parseUserInfo(data))
    return parseUserInfo(data)
  }

  return {
    userInfo,
    getUserInfo
  }
}

export default useUserInfo
