import { storeToRefs } from 'pinia'
import clientsApi from '@/api/clients-api'

import type { ITeams, IcreateTeams, IdeleteTeams } from '@/modules/settings/interfaces/Teams'
import { useTeamsStore } from '@/modules/settings/stores/teams'
import MODALS from '@/modules/shared/utils/modals_enum'
import { TEAMS } from '@/api/url'

const useTeams = () => {
  const store = useTeamsStore()
  const { teams } = storeToRefs(store)

  const getTeams = async ():Promise<ITeams[]> => {
    const { data } = await clientsApi.get(TEAMS)
    if (data) store.setTeams(data)
    return data
  }

  const createTeams = async (data: IcreateTeams):Promise<void> => {
    await clientsApi.post<ITeams>(TEAMS, data)
  }

  const deleteTeams = async (data: IdeleteTeams):Promise<void> => {
    await clientsApi.delete<ITeams>(TEAMS, data)
  }

  return {
    teams,

    // Method
    getTeams,
    createTeams,
    deleteTeams,

    removeData: (teamsIds:string[]) => {
      const maxLength = 50
      const ts: string[] = []
      teamsIds.forEach(t => {
        teams.value.forEach((team) => {
          if (t === team.id) ts.push(team.name)
        })
        return t.length > maxLength ? t.slice(0, maxLength) + '...' : t
      })

      return {
        name: MODALS.removeModal,
        data: {
          type: 'teams',
          title: `Are you sure you want to remove ${teamsIds.length > 1 ? 'these teams' : 'this team'}?`,
          text: `The "${ts.join(', ')}" ${teamsIds.length > 1 ? 'teams' : 'team'} will be permanently deleted, but the mailboxes inside it will still be tracked.`,
          data: teamsIds
        }
      }
    }
  }
}

export default useTeams
