import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Toast from 'vue-toastification'
import ClickOutside from '@/modules/shared/helpers/ClickOutsideDirective'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'
import './assets/main.css'

const app = createApp(App)

app.directive('click-outside', ClickOutside)

app.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 3,
  newestOnTop: true,
  position: 'bottom-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.45,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
})

if (import.meta.env.VITE_BUGSNAG_KEY) {
  window.bugsnagClient = Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_KEY as string,
    releaseStage: import.meta.env.VITE_NODE_ENV as string === 'production' ? import.meta.env.VITE_NODE_ENV as string : 'development',
    appVersion: import.meta.env.VITE_CI_COMMIT_SHORT_SHA,
    notifyReleaseStages: import.meta.env.VITE_NODE_ENV as string === 'production' ? import.meta.env.VITE_NODE_ENV as string : 'development',
    autoBreadcrumbs: true,
    maxBreadcrumbs: 40,
    plugins: [new BugsnagPluginVue()]
  })
  const bugsnagVue = Bugsnag.getPlugin('vue')
  app.use(bugsnagVue)
}

app.use(createPinia())
app.use(router)

app.mount('#app')
