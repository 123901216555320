import type { ContactGroups } from '@/modules/settings/interfaces/ContactGroups'
import { storeToRefs } from 'pinia'
import clientsApi from '@/api/clients-api'

import { useContactGroupsStore } from '@/modules/settings/stores/contactGroups'
import { CONTACTGROUPS } from '@/api/url'

const useContactGroups = () => {
  const storeContactGroups = useContactGroupsStore()
  const { contactGroups, contacts } = storeToRefs(storeContactGroups)

  const handleOrderGroup = (data: ContactGroups[]) => {
    data.sort(function (a, b) {
      if (a.contact_group > b.contact_group) return 1
      if (a.contact_group < b.contact_group) return -1
      return 0
    })
  }

  const getContactGroups = async ():Promise<ContactGroups[]> => {
    const { data } = await clientsApi.get(CONTACTGROUPS)

    if (data.contact_groups.length && !contactGroups.value.length) {
      handleOrderGroup(data.contact_groups)

      if (!contacts.value.length) {
        const { data: data2 } = await clientsApi.get(`${CONTACTGROUPS}${data.contact_groups[0]?.contact_group}`)
        const a = data2.contact_values.map((a: { id:string; address:string; domain: string; }) => {
          if (a.address) return { id: a.id, item: a.address }
          return { id: a.id, item: a.domain }
        })
        storeContactGroups.setContact(a)
      }
    }
    storeContactGroups.setContactGroups(data.contact_groups)
    return data
  }

  const getContacts = async (name:string):Promise<ContactGroups[]> => {
    const { data } = await clientsApi.get(`${CONTACTGROUPS}${name}`)
    const a = data.contact_values.map((a: { id:string; address:string; domain: string; }) => {
      if (a.address && a.address !== null) return { id: a.id, item: a.address }
      else return { id: a.id, item: a.domain }
    })
    storeContactGroups.setContact(a)
    return data
  }

  const AddContactGroup = async (data: { name: string; mailboxes: string[]; }):Promise<void> => {
    const b = data.mailboxes.map((a) => {
      if (a.includes('@')) return { domain: a.slice(a.indexOf('@') + 1), address: a }
      return { domain: a, address: null }
    })
    await clientsApi.post(`${CONTACTGROUPS}${data.name}`, { contact_values: b })
  }

  const renameContactGroup = async (data: { name: string; newName: string; }):Promise<void> => {
    await clientsApi.patch(`${CONTACTGROUPS}`, { contact_group_name: data.name, new_name: data.newName })
  }

  const removeContactGroup = async (data: { name: string; }):Promise<void> => {
    await clientsApi.delete(`${CONTACTGROUPS}`, { data: { contact_group_name: data.name } })
  }

  const removeContact = async (data: { name: string; mailboxes: string[]; }):Promise<void> => {
    await clientsApi.delete(`${CONTACTGROUPS}${data.name}`, { data: { contact_values_ids: data.mailboxes } })
  }

  return {
    // properties
    contactGroups,
    contacts,

    // Method
    getContactGroups,
    getContacts,
    AddContactGroup,
    renameContactGroup,
    removeContactGroup,
    removeContact
  }
}

export default useContactGroups
