export const DASHBOARD = '/portal/api/dashboards'
export const LOGIN_G = '/portal/login/google-oauth2'
export const LOGIN_M = '/portal/login/microsoft-graph'
export const ME = '/portal/api/me/'
export const MAILBOXES = '/portal/api/settings/mailboxes/'
export const USERS = '/portal/api/settings/users/'
export const MANAGER_MAILBOXES = '/portal/api/settings/managers/'
export const TEAMS = '/portal/api/settings/teams/'
export const ALERTS = '/portal/api/alerts/'
export const COMPANYBUSINESSHOURS = '/portal/api/settings/company-business-hours/'
export const BUSINESSHOURS = '/portal/api/settings/business-hours/'
export const METRICADJUSTMENTS = '/portal/api/settings/filtering-settings/'
export const CONTACTGROUPS = '/portal/api/settings/contact-groups/'
