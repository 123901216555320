<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { ArrowTopRightOnSquareIcon, PlusIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useToast } from 'vue-toastification'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'
import type { IMetricAdjustmentsFront } from '@/modules/settings/interfaces/MetricAdjustments'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import InputMultiple from '@/modules/shared/components/InputMultiple.vue'
import { emailReg, domainReg, stringReg, subdomainReg } from '@/modules/shared/utils/reg'
import selectSimple from '@/modules/shared/components/SelectSimple'
import useMetricAdjustments from '@/modules/settings/composables/useMetricAdjustments'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

const { createMetricAdjustments, createInternalDomains, whitelisted, internalDomains, excludeEmails, getMetricAdjustments } = useMetricAdjustments()
const toast = useToast()
const me = useUserInfo().userInfo.value
const options = ref<IMetricAdjustmentsFront[]>([])
const domain = ref('')
const domains = ref<Iitems[]>([])
const excludeDomains = ref<Iitems[]>([])
const lastItem = ref<number>(1)
const isSameDomains = ref(true)
const isSameEmails = ref(true)
const finishRequest = ref(false)
const readOnly = me.features?.metric_adjustments?.read_only

watch(excludeEmails, () => {
  if (options.value.length === 0 && !!lastItem.value) options.value = excludeEmails.value
})
watch(internalDomains, () => {
  excludeDomains.value = [...whitelisted.value.map((item) => ({ item, status: true, notTraking: false, canNotDelete: true })), ...internalDomains.value]
  if (domains.value.length === 0) domains.value = excludeDomains.value
})
watch(domains, (newVal) => {
  if (newVal !== excludeDomains.value) isSameDomains.value = false
})
watch(options, (newVal, oldVal) => {
  if (newVal !== oldVal && newVal !== excludeEmails.value) isSameEmails.value = false
})
const handleDisabled = () => (isSameEmails.value = false)

const userRoleTitle = me.emailProvider === 'G' ? ['Address', 'Domain', 'Subject', 'Label'] : ['Address', 'Domain', 'Subject', 'Category', 'Folder']
const userRoleTitle2 = ['is', 'contains']

const inactiveExcludeEmails = computed(() => ((!options.value.length && !!lastItem.value) || options.value.some((o) => o.items.length === 0 || isSameEmails.value || o.items.some((i) => i.status === false))))

const inactiveExcludeDomains = computed(() => !domains.value.length || isSameDomains.value || domains.value.some(({ status }: {status: boolean}) => status === false))
const errorExcludeDomains = computed(() => domains.value.some(({ status }: {status: boolean}) => status === false))

const addCondition = () => { options.value = [...options.value, { id: options.value.length + 1, select1: 'Address', select2: 'is', text: '', items: [], space: false }] }
const reg = (item: IMetricAdjustmentsFront) => {
  if (item.select1 === 'Address' && item.select2 === 'is') return (e:string) => (!(emailReg.test(e)))
  else if (item.select1 === 'Domain' && item.select2 === 'is') return (e:string) => (!(domainReg.test(e) || subdomainReg.test(e)))
  else if (item.select1 === 'Subject' || item.select1 === 'Label' || item.select1 === 'Folder' || item.select1 === 'Category') {
    item.space = true
    return () => false
  } else return (e:string) => (!(stringReg.test(e)))
}

const deleteItem = (id:number) => {
  const index = options.value.findIndex((option:{id:number}) => option.id === id)
  options.value.splice(index, 1)
  if (!options.value.length) lastItem.value = 0
}

const updateSelect1 = (index:number, value:string) => {
  options.value[index].select1 = value
  updateItems(index)
}
const updateSelect2 = (index:number, value:string) => {
  options.value[index].select2 = value
  updateItems(index)
}

const updateItems = (index:number) => {
  const onlyItems = options.value[index].items.map((e) => e.item)
  options.value[index].items = onlyItems.map((e) => {
    if (reg(options.value[index])(e)) return { item: e, status: false }
    return { item: e, status: true }
  })
}

const saveExcludeEmails = () => {
  if (!inactiveExcludeEmails.value) {
    lastItem.value = 1
    isSameEmails.value = true
    createMetricAdjustments(options.value).then(async () => {
      await getMetricAdjustments()
      if (!finishRequest.value) finishRequest.value = true
      toast.success({
        component: ToastComponent,
        props: {
          title: 'New exclusions condition saved',
          messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
        },
        options: { icon: CheckCircleIcon }
      }, {
        timeout: 10000
      })
    })
    finishRequest.value = false
  }
}

const saveExcludeDomains = () => {
  if (!inactiveExcludeDomains.value) {
    isSameDomains.value = true
    createInternalDomains(domains.value.filter((d) => !d.canNotDelete)).then(async () => {
      await getMetricAdjustments()
      if (!finishRequest.value) finishRequest.value = true
      toast.success({
        component: ToastComponent,
        props: {
          title: 'New internal domains condition saved',
          messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
        },
        options: { icon: CheckCircleIcon }
      }, {
        timeout: 10000
      })
    })
    finishRequest.value = false
  }
}
</script>
<template>
  <div class="mt-6 flex gap-8">
    <div class="basis-2/5 min-[1580px]:basis-2/6">
      <p class="text-sm leading-5 font-medium text-gray-700 mb-1">Exclude Emails</p>
      <p class="text-sm leading-5 font-normal text-gray-500">Prevent emails with certain conditions from showing <br> up in your dashboard.</p>
      <a href="https://docs.enterprise.emailmeter.com/workspace-setup/exclude-emails" target="_blank" class="text-sm leading-5 font-semibold text-blue-600 mt-3 flex items-center gap-[6px] cursor-pointer">
        Learn more
        <ArrowTopRightOnSquareIcon class="inline h-4 w-4" aria-hidden="true" />
      </a>
    </div>
    <div class="basis-3/5 min-[1580px]:basis-4/6 bg-white rounded-md shadow-sm">

      <div class="p-6">
        <div id="option-containers">
          <div v-for="(option, index) in options" :key="option.id" class="flex items-start gap-2 mb-5 gap-y-2">
            <div v-if="index>0" class="flex items-center min-h-[38px]">
              <span class="text-sm leading-5 font-medium text-gray-500">OR</span>
            </div>
              <div v-if="index===0" class="w148">
                <selectSimple :read-only="readOnly" :optionSimple="userRoleTitle"  :valueSimple="options[index].select1" @update:valueSimple="(value: string) => updateSelect1(index, value)" />
              </div>

              <div v-else class="w120">
                <selectSimple :read-only="readOnly" :optionSimple="userRoleTitle"  :valueSimple="options[index].select1" @update:valueSimple="(value: string) => updateSelect1(index, value)" />
              </div>

            <div class="w120">
              <selectSimple :read-only="readOnly" :optionSimple="userRoleTitle2" :valueSimple="options[index].select2" @update:valueSimple="(value: string) => updateSelect2(index, value)"/>
            </div>
            <div class="grow">
              <InputMultiple
                class="InputMultipleExcludeEmails max-height128"
                v-model:text="options[index].text"
                v-model:items="options[index].items"
                v-model:item="isSameEmails"
                placeholder=""
                :reg="reg(options[index])"
                :allowedDomains="[]"
                :onlyExistEmails="[]"
                :disabled="readOnly"
                :space="options[index].space"
                @input="handleDisabled"
                />
            </div>

            <div class="deleteItems flex items-center min-h-[38px]">
              <XMarkIcon @click="deleteItem(option.id)" class="inline h-6 w-6 text-gray-400 hover:text-gray-600 cursor-pointer" aria-hidden="true" />
            </div>
          </div>
        </div>
        <button :disabled="readOnly" @click="addCondition" class="inline-block">
          <div class="flex items-center gap-[6px] cursor-pointer" :class="readOnly ? '!cursor-not-allowed' : 'hover:text-gray-700'">
            <PlusIcon class="inline h-4 w-4 text-gray-500" aria-hidden="true" />
            <p class="text-sm leading-5 font-semibold text-gray-500" >Add condition</p>
          </div>
        </button>
      </div>

      <div v-if="!readOnly" class="py-3 px-6 flex justify-end border-t border-gray-200">
        <ButtonG text="Save" class="!w-[67px]" :inactive="inactiveExcludeEmails" :is-request="true" :finish-request="finishRequest" @Click="saveExcludeEmails" />
      </div>
    </div>
  </div>
  <div class="mt-6 flex gap-8">
    <div class="basis-2/5 min-[1580px]:basis-2/6">
      <p class="text-sm leading-5 font-medium text-gray-700 mb-1">Internal domains</p>
      <p class="text-sm leading-5 font-normal text-gray-500">Manage the domains that you want to categorize as internal communications on your statistics.</p>
      <a href="https://docs.enterprise.emailmeter.com/workspace-setup/internal-domains" target="_blank" class="text-sm leading-5 font-semibold text-blue-600 mt-3 flex items-center gap-[6px] cursor-pointer">
        Learn more
        <ArrowTopRightOnSquareIcon class="inline h-4 w-4" aria-hidden="true" />
      </a>
    </div>
    <div class="basis-3/5 min-[1580px]:basis-4/6 bg-white rounded-md shadow-sm">

      <div class="p-6">
        <div class="inline-block">
          <div class="flex items-center gap-[6px]">
            <p class="text-sm leading-5 font-medium text-gray-900 mb-2">Internal domains</p>
          </div>
        </div>
        <InputMultiple
          class="InputMultipleInternalDomains"
          placeholder="Type in or paste a list of domains"
          maxHeight="max-h-32"
          v-model:text="domain"
          v-model:items="domains"
          :reg="(e:string) => (!(domainReg.test(e) || subdomainReg.test(e)))"
          :allowedDomains="[]"
          :onlyExistEmails="[...whitelisted]"
          :disabled="readOnly"
        />
        <span v-if="errorExcludeDomains" class="mt-2 text-red-600 font-medium leading-4 text-3">Some of the entries are not valid domains</span>
      </div>

      <div v-if="!readOnly" class="py-3 px-6 flex justify-end border-t border-gray-200">
        <ButtonG text="Save" class="!w-[67px]" :inactive="inactiveExcludeDomains" :is-request="true" :finish-request="finishRequest" @Click="saveExcludeDomains" />
      </div>
    </div>
  </div>
</template>

<style>
  .w148 > div{
    width: 148px;
  }

  .w120 > div{
    width: 120px;
  }

  .max-height128 > div {
    max-height: 128px;
  }
</style>
