import { computed } from 'vue'
import { storeToRefs } from 'pinia'

import clientsApi from '@/api/clients-api'
import SettingsParser from '@/modules/settings/utils/parser/settings'
import type { IUsers, IcreateUsers, IdeleteUsers } from '@/modules/settings/interfaces/Users'
import { useUsersStore } from '@/modules/settings/stores/users'
import { USERS, MANAGER_MAILBOXES } from '@/api/url'
import MODALS from '@/modules/shared/utils/modals_enum'

const useUsers = () => {
  const store = useUsersStore()
  const { users } = storeToRefs(store)

  const getUsers = async ():Promise<IUsers[]> => {
    const { data } = await clientsApi.get(USERS)
    if (data) store.setUsers(SettingsParser.parseUsers(data))
    return SettingsParser.parseUsers(data)
  }

  const createManagerUser = (id:string, mailboxes:string[], teams:string[]) => clientsApi.post(`${MANAGER_MAILBOXES}${id}/`, { mailboxes, teams })

  const createUsers = async (data: IcreateUsers):Promise<void> => {
    await clientsApi.post<IUsers[]>(USERS, data).then((res) => {
      if (data.role === 'manager' && !window.location.host.includes('localhost')) {
        createManagerUser(res.data[0].id, data.manage_mailboxes, data.manage_teams)
      }
    })
  }

  const deleteUsers = async (data: IdeleteUsers):Promise<void> => {
    await clientsApi.delete<IUsers>(USERS, data)
  }

  return {
    users,

    // Method
    getUsers,
    createUsers,
    deleteUsers,
    createManagerUser,
    removeData: (users:string[]) => ({
      name: MODALS.removeModal,
      data: {
        type: 'users',
        title: `Are you sure you want to remove ${users.length > 1 ? 'these users' : 'this user'}?`,
        text: `${users.join(', ')} will lose access to see email statistics.`,
        data: users
      }
    }),

    // computed
    onlyExistEmails: computed(() => [...users.value.map(m => m.email)])
  }
}

export default useUsers
