/* eslint-disable @typescript-eslint/no-explicit-any */
import { storeToRefs } from 'pinia'
import { useTableStore } from '@/modules/settings/stores/table'

const useTable = () => {
  const storeTable = useTableStore()
  const { setSelectedItems } = useTableStore()
  const { setSearch, setTypeOrder, setAsc } = storeTable
  const { asc, search, typeOrder, selectedItems } = storeToRefs(storeTable)

  //
  const indeterminate = (items:number) => selectedItems.value.length > 0 && selectedItems.value.length < items
  const selectAll = (e:Event, items:string[]) => { setSelectedItems((e.target as HTMLInputElement).checked ? items.map((p:string) => p) : []) }
  const selectItem = (e:Event, email:string) => { setSelectedItems((e.target as HTMLInputElement).checked ? [email, ...selectedItems.value] : selectedItems.value.filter((item) => item !== email)) }
  const unselectAll = () => { setSelectedItems([]) }
  //
  const filterInputVal = (e: string) => { setSearch(e) }
  const clearSearch = () => { setSearch('') }

  return {
    // variable
    asc,
    search,
    typeOrder,
    selectedItems,

    // Methods
    filterInputVal,
    orderBy: (o:string) => {
      setAsc(typeOrder.value !== o ? true : !asc.value)
      setTypeOrder(o)
    },
    setAsc,
    setTypeOrder,
    selectAll,
    selectItem,
    unselectAll,
    clearSearch,
    filterData: (data:any, type:string[]) => {
      const is = data.filter((item: any) => {
        for (const attr of type) {
          if (item[attr] && item[attr].toLowerCase().includes(search.value.toLowerCase())) return true
        }
        return false
      }).sort((a:any, b:any) => {
        let nameA
        let nameB
        if (['dataAdded', 'created', 'lastSent', 'createdBy'].includes(typeOrder.value)) {
          const d1 = <any> new Date(a[typeOrder.value])
          const d2 = <any> new Date(b[typeOrder.value])
          return (asc.value) ? d1 - d2 : d2 - d1
        } else if (typeOrder.value === 'team-members') {
          nameA = a.members.length
          nameB = b.members.length
        } else if (typeOrder.value === 'tracking') {
          nameA = b.tracking
          nameB = a.tracking
        } else if (typeOrder.value === 'access-to') {
          nameA = a.role === 'Admin' ? 0 : a.dataFrom.mailboxes.length === 0 ? 1 : a.dataFrom.mailboxes.length + 1
          nameB = b.role === 'Admin' ? 0 : b.dataFrom.mailboxes.length === 0 ? 1 : b.dataFrom.mailboxes.length + 1
        } else {
          nameA = a[typeOrder.value]?.toLowerCase()
          nameB = b[typeOrder.value]?.toLowerCase()
        }

        const orderby = asc.value ? [-1, 1] : [1, -1]
        if (nameA < nameB) return orderby[0]
        else if (nameA > nameB) return orderby[1]
        return -1
      })
      return is
    },
    resetTable: (type: string) => {
      unselectAll()
      setAsc(true)
      setTypeOrder(type)
    },
    // computed
    indeterminate
  }
}

export default useTable
