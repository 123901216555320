import type { IMailboxes, MailboxesInitial } from '@/modules/settings/interfaces/Mailboxes'
import type { IUsers, IUsersInitial, IRolesMapping } from '@/modules/settings/interfaces/Users'

export default class SettingsParser {
  static parseMailboxes (data: MailboxesInitial[]) {
    const parsed: IMailboxes[] = []
    const status = {
      sync_error: 'Error',
      loading_data: 'Loading data',
      working: 'Active'
    }

    data.forEach((mailbox: MailboxesInitial) => {
      parsed.push({
        bhs: {
          hover: false,
          timezone: '',
          businessHours: []
        },
        bhsStatus: mailbox.bhs_status,
        email: mailbox.email,
        dataAdded: mailbox.created,
        dataFrom: mailbox.data_from,
        status: status[mailbox.status],
        name: mailbox.full_name
      })
    })
    return parsed
  }

  static parseUsers (data: IUsersInitial[]) {
    const parsed: IUsers[] = []
    const ROLES_MAPPING: IRolesMapping = {
      admin: 'Admin',
      manager: 'Manager',
      viewer: 'Viewer'
    }
    data.forEach((user: IUsersInitial) => {
      parsed.push({
        id: user.id,
        dataFrom: user.can_see_data_for,
        email: user.email,
        role: ROLES_MAPPING[user.role as keyof IRolesMapping],
        tracking: user.tracked,
        name: user.full_name
      })
    })

    return parsed
  }
}
