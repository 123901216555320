import { storeToRefs } from 'pinia'
import clientsApi from '@/api/clients-api'

import type { Iitems } from '@/modules/shared/interfaces/inputMultiple'
import type { IMetricAdjustments, IMetricAdjustmentsFront } from '@/modules/settings/interfaces/MetricAdjustments'
import { useMetricAdjustmentsStore } from '@/modules/settings/stores/metricAdjustments'
import { METRICADJUSTMENTS } from '@/api/url'
import { excludedFiltersFront, internalDomainsFront, excludedFiltersBack } from '@/modules/settings/utils/helpers/metricAdjustments'

const useMetricAdjustments = () => {
  const storeMetricAdjustments = useMetricAdjustmentsStore()
  const { excludeEmails, internalDomains, whitelisted } = storeToRefs(storeMetricAdjustments)

  const getMetricAdjustments = async ():Promise<IMetricAdjustments> => {
    const { data } = await clientsApi.get(`${METRICADJUSTMENTS}`)

    storeMetricAdjustments.setExcludeEmails(excludedFiltersFront(data.filtering_settings.excluded_filters))
    storeMetricAdjustments.setInternalDomains(internalDomainsFront(data.filtering_settings.internal_domains))
    storeMetricAdjustments.setWhitelisted([...data.client_domains.default_domains])

    return data
  }

  const createMetricAdjustments = async (data: IMetricAdjustmentsFront[]):Promise<void> => {
    await clientsApi.post(`${METRICADJUSTMENTS}`, {
      client_domains: { default_domains: [...internalDomains.value] },
      filtering_settings: {
        excluded_filters: excludedFiltersBack(data),
        internal_domains: [...internalDomains.value.map(id => id.item)]
      }
    })
  }

  const createInternalDomains = async (data: Iitems[]):Promise<void> => {
    await clientsApi.post(`${METRICADJUSTMENTS}`, {
      client_domains: { default_domains: [...internalDomains.value] },
      filtering_settings: {
        excluded_filters: excludedFiltersBack(excludeEmails.value),
        internal_domains: data.map((d) => d.item)
      }
    })
    storeMetricAdjustments.setInternalDomains(data)
  }

  return {
    // properties
    excludeEmails,
    internalDomains,
    whitelisted,

    // Method
    getMetricAdjustments,
    createMetricAdjustments,
    createInternalDomains
  }
}

export default useMetricAdjustments
