<script setup lang="ts">
import type { PropType } from 'vue'

import { EllipsisHorizontalIcon, CheckIcon } from '@heroicons/vue/20/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import ButtonR from '@/modules/shared/components/ButtonRemove.vue'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import useModal from '@/modules/shared/composable/useModal'
import MODALS from '@/modules/shared/utils/modals_enum'
import type { IUsers } from '@/modules/settings/interfaces/Users'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useTable from '@/modules/settings/composables/useTable'
import useUsers from '@/modules/settings/composables/useUsers'

const { selectedItems, selectItem } = useTable()
const { removeModal, showModalUpdateUser } = useModal()
const { removeData } = useUsers()

const props = defineProps({
  users: {
    type: Object as PropType<IUsers[]>,
    required: true
  },
  readOnly: {
    type: Boolean,
    default: false
  }
})

// statements
const { userInfo } = useUserInfo()
const { mailboxes } = useMailboxes()

const handleText = (mailboxesData:string[], teamsData:string[]) => {
  if (mailboxesData?.length && teamsData?.length) return `${teamsData?.length} ${teamsData?.length > 1 ? 'teams' : 'team'} and ${mailboxesData?.length} mailboxes`
  else if (!mailboxesData?.length && teamsData?.length) return `Own mailbox and ${teamsData?.length} ${teamsData?.length > 1 ? 'teams' : 'team'}`
  return `${mailboxesData?.length} mailboxes`
}

const isTraking = (t:string) => mailboxes.value.filter((p:{email:string}) => (p.email === t)).length > 0
const dataFrom = (role:string, mailboxesData:string[], teamsData:string[]) => {
  return role === 'Admin' ? 'All mailboxes' : !mailboxesData?.length && !teamsData?.length ? 'Own mailbox' : handleText(mailboxesData, teamsData)
}

const isDisabled = (email:string) => userInfo.value?.email === email
</script>

<template>
  <tbody class="divide-y divide-gray-200 ">
    <tr v-for="user in users" :key="user.email" :class="[selectedItems.includes(user.email) && 'bg-gray-50']" class="h-18">
      <td class="relative px-7 sm:w-12 sm:px-6">
        <input
          v-if="!(isDisabled(user.email) || props.readOnly)"
          type="checkbox"
          class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-500 focus:ring-0 focus:ring-offset-0"
          :checked="selectedItems.includes(user.email)"
          @change="selectItem($event, user.email)"
        />
      </td>
      <td class="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900">
        {{ user.email }}
        <br >
        <p class="text-gray-600 font-normal">{{ user.name }}</p>
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {{ user.role }}
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {{ dataFrom(user.role, user.dataFrom?.mailboxes, user.dataFrom?.teams) }}
      </td>
      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <CheckIcon v-if="isTraking(user.email)" class="h-4 w-4 text-green-500" aria-hidden="true" />
      </td>
      <td v-if="!props.readOnly" class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-3">
        <Menu v-if="!isDisabled(user.email)" as="div" class="relative inline-block text-left">
          <div>
            <MenuButton class="flex items-center rounded p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-0 focus:ring-offset-0">
              <span class="sr-only">Open options</span>
              <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="py-1 absolute right-0 z-50 w-[164px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div>
                <MenuItem>
                  <ButtonR
                    class="!text-left"
                    :inactive="isDisabled(user.email)"
                    layout="ButtonRemove"
                    type-color="gray"
                    text="Edit role"
                    @click="showModalUpdateUser({ name: MODALS.updateUser, data:{ id: user.id,name: MODALS.updateUser, email: user.email ,role: user.role, mailboxes: user.dataFrom?.mailboxes, teams: user.dataFrom?.teams }})"
                  />
                </MenuItem>
                <MenuItem>
                  <ButtonR :inactive="isDisabled(user.email)" type-color="red" text="Remove user" @click="removeModal(removeData([user.email]))" />
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </td>
    </tr>
  </tbody>
</template>

<style scoped></style>
