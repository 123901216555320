<script setup lang="ts">
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import { useToast } from 'vue-toastification'
import type { IModalData2, IModalDataMailboxes, IModalDataTeam } from '@/modules/shared/interfaces/Modal'

import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useModal from '@/modules/shared/composable/useModal'
import useContactGroups from '@/modules/settings/composables/useContactGroups'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import { numberToWords } from '@/modules/settings/utils/helpers/numbers'
import useTeams from '@/modules/settings/composables/useTeams'

const toast = useToast()
const { modalData, hideModal } = useModal()
const { createTeams, getTeams } = useTeams()

const { AddContactGroup, getContacts, renameContactGroup, getContactGroups } = useContactGroups()
const { createMailboxes, getMailboxes } = useMailboxes()

const ConfirmAddContact = () => {
  const data = (modalData.value as IModalData2)

  const is = data.items.map((d) => (d.item))

  is.filter((item, index) => is.indexOf(item) === index)

  AddContactGroup({ name: data.name!, mailboxes: is.filter((item, index) => is.indexOf(item) === index) }).then(() => {
    getContacts(data.name).then(async () => {
      await getContactGroups()
      hideModal()
      if (data.items.length === 1) toast.success('New contact added!')
      else toast.success('New contacts added!')
    })
  })
}

const ConfirmAddContactGroup = () => {
  const data = (modalData.value as IModalData2)

  const is = data.items.map((d) => d.item)

  AddContactGroup({ name: data.name, mailboxes: is.filter((item, index) => is.indexOf(item) === index) }).then(async () => {
    await getContactGroups()
    hideModal()
    toast.success('New contact group added!')
  })
}

const ConfirmRenameContactGroup = () => {
  const data = (modalData.value as IModalData2)

  renameContactGroup({ name: data.name, newName: (data.newName as string) }).then(async () => {
    await getContactGroups()
    hideModal()
    toast.success('Contact group modified')
  })
}

const ConfirmCreateMailboxes = () => {
  const data = (modalData.value as IModalDataMailboxes)

  createMailboxes(data.emailData).then(async () => {
    await getMailboxes()
    hideModal()
    const m = data.emailData.emails.length
    toast.success(`${numberToWords(m)} new mailbox${m > 1 ? 'es' : ''} added!`)
  })
}

const ConfirmCreateTeams = () => {
  const data = (modalData.value as IModalDataTeam)

  createTeams(data.newTeamsData).then(async () => {
    await getTeams()
    hideModal()
    data.hasDataModal
      ? toast.success('Team edited')
      : toast.success('Team created')
  })
}

const Confirm = () => {
  switch ((modalData.value as IModalData2).type) {
    case 'AddContact':
      ConfirmAddContact()
      break
    case 'AddContactGroup':
      ConfirmAddContactGroup()
      break
    case 'RenameContactGroup':
      ConfirmRenameContactGroup()
      break
    case 'CreateMailboxes':
      ConfirmCreateMailboxes()
      break
    case 'createTeams':
      ConfirmCreateTeams()
      break
    default:
      break
  }
}
</script>

<template>
  <div id="modalSaveExcludeEmail" class="p-6 pb-0 overflow-auto">
    <div class="bg-indigo-100 w-12 h-12 flex items-center justify-center rounded-full ">
      <ExclamationCircleIcon class="w-6 h-6 text-indigo-500" />
    </div>
    <p class="text-gray-900 font-semibold leading-8 text-lg mt-5">{{ (modalData as IModalData2).msg.title }}</p>
    <p class="text-sm leading-5 font-normal text-gray-500 pt-2">{{ (modalData as IModalData2).msg.text }}</p>
  </div>

  <ButtonsModal @submit="Confirm"/>
</template>

<style scoped>

</style>
