import { storeToRefs } from 'pinia'
import { useBusinessHoursStore } from '@/modules/settings/stores/businessHours'
import type { IIBhs } from '@/modules/settings/interfaces/BusinessHours'
import clientsApi from '@/api/clients-api'
import { COMPANYBUSINESSHOURS } from '@/api/url'
import { weekdaysFormat } from '@/modules/settings/utils/parser/bhs'

const useCompanyBusinessHours = () => {
  const businessHoursStore = useBusinessHoursStore()
  const { businessHours } = storeToRefs(businessHoursStore)

  const getCompanyBusinessHours = async ():Promise<IIBhs> => {
    const { data } = await clientsApi.get(COMPANYBUSINESSHOURS)
    businessHoursStore.setBusinessHours(weekdaysFormat(data))
    return data
  }

  const createCompanyBusinessHours = async (businessHours: IIBhs):Promise<void> => {
    await clientsApi.post<IIBhs>(COMPANYBUSINESSHOURS, {
      business_hours: businessHours.business_hours,
      timezone: businessHours.timezone.replace(' ', '_')
    })
  }

  const deleteCompanyBusinessHours = async ():Promise<void> => {
    await clientsApi.delete(`${COMPANYBUSINESSHOURS}`)
  }

  return {
    // properties
    businessHours,

    // Method
    getCompanyBusinessHours,
    createCompanyBusinessHours,
    deleteCompanyBusinessHours
  }
}

export default useCompanyBusinessHours
