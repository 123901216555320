<script setup>
import { onBeforeMount, reactive, ref, computed, watch, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import SpinnerComponent from '@/modules/shared/components/SpinnerComponent.vue'
import ModalComponent from '@/modules/shared/components/ModalComponent.vue'
import PanelLayout from '@/modules/shared/pages/PanelLayout.vue'
import { useMainStore } from '@/modules/shared/stores/mainStore.ts'
import useUserInfo from './modules/shared/composable/useUserInfo'
import useDashboard from '@/modules/dashboard/composables/useDashboard'
import useAlerts from '@/modules/explore/composables/useAlerts'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useCompanyBusinessHours from '@/modules/settings/composables/useCompanyBusinessHours'
import useContactGroups from '@/modules/settings/composables/useContactGroups'
import useUsers from '@/modules/settings/composables/useUsers'
import useTeams from '@/modules/settings/composables/useTeams'
import useMetricAdjustments from '@/modules/settings/composables/useMetricAdjustments'
import useModal from '@/modules/shared/composable/useModal'
import {
  HomeIcon, Cog8ToothIcon, QuestionMarkCircleIcon, BellIcon, LightBulbIcon
} from '@heroicons/vue/20/solid'
import { XMarkIcon } from '@heroicons/vue/24/solid'

const router = useRouter()
const route = useRoute()
const { getUserInfo } = useUserInfo()
const { getDashboard } = useDashboard()
const { getAlerts } = useAlerts()
const { getMailboxes } = useMailboxes()
const { getCompanyBusinessHours } = useCompanyBusinessHours()
const { getContactGroups } = useContactGroups()
const { getUsers } = useUsers()
const { getTeams } = useTeams()
const { getMetricAdjustments } = useMetricAdjustments()

const sideBar = reactive({
  headerItem: [],
  footerItem: []
})
const mainStore = useMainStore()
const { showModal } = useModal()
const isOpen = ref(false)
const user = ref()
const mounting = ref(true)
const url = new URL(window.location.href)
const handleOpen = computed(() => isOpen.value)

watch(
  () => route.path,
  (newPath) => {
    if (newPath) isOpen.value = false
  }
)

onBeforeMount(() => {
  if (url.pathname !== '/login') {
    const savedToken = localStorage.getItem('userToken') || ''
    const urlToken = url.searchParams.get('token') || ''
    window.dataLayer = window.dataLayer || []

    if (savedToken.length > 0 || urlToken.length > 0 || import.meta.env.VITE_BACKEND_HOST === 'http://localhost:3000') {
      localStorage.setItem('userToken', urlToken.length > 0 ? urlToken : savedToken)
      initGTM()
      if (urlToken !== '') router.push({ name: 'dashboard' })
    } else {
      window.dataLayer.push({
        origin: 'enterprise_portal',
        event: 'datalayer_ready'
      })
      localStorage.setItem('errorLogin', url.searchParams.get('error') || '')
      router.push({ name: 'login' })
    }
  }
  updateWindowWidth()
  window.addEventListener('resize', updateWindowWidth)
})

const updateWindowWidth = () => {
  const windowWidth = window.innerWidth || document.body.clientWidth
  mainStore.setWindowWidth(windowWidth)
}

const removeListener = () => {
  window.removeEventListener('resize', updateWindowWidth)
}

const initGTM = async () => {
  const me = await getUserInfo()
  user.value = me
  initApp()

  window.dataLayer.push({
    email: me.email,
    full_name: me.name,
    user_role: me.roles,
    client_name: me.clientName,
    email_provider: me.emailProvider,
    mixpanel_id: me.mixpanelId,
    origin: 'enterprise_portal',
    event: 'datalayer_ready'
  })

  if (me.features?.mailboxes && !me.features?.mailboxes?.enabled) sideBar.headerItem[2].to = '/settings/users'
  if (me.roles !== 'admin') sideBar.headerItem.splice(2, 1)
  if ((me.features?.alerts && !me.features?.alerts?.enabled) || me.roles !== 'admin') sideBar.headerItem.splice(1, 1)
  if (import.meta.env.VITE_BUGSNAG_KEY) initErrorHandlers(me)
}

const initApp = async () => {
  await getDashboard()
  if (user.value.roles === 'admin') {
    await getAlerts()
    await getMailboxes()
    await getUsers()
    await getTeams()
    await getCompanyBusinessHours()
    await getMetricAdjustments()
    await getContactGroups()
  }
  mounting.value = false
}

const initErrorHandlers = (me) => {
  window.bugsnagClient._user = { id: me.email, name: me.full_name }
}

onBeforeUnmount(() => removeListener())

sideBar.headerItem = [
  {
    typeComponent: 'router-link',
    icon: HomeIcon,
    text: 'Dashboard',
    to: '/dashboard'
  },
  {
    typeComponent: 'router-link',
    icon: BellIcon,
    text: 'Alerts',
    to: '/alerts/'
  },
  {
    icon: Cog8ToothIcon,
    typeComponent: 'router-link',
    text: 'Settings',
    to: '/settings/mailboxes'
  }
]
sideBar.footerItem = [
  {
    icon: LightBulbIcon,
    typeComponent: 'button',
    text: 'Share feedback',
    handlerClick: () => showModal({ name: 'sendFeedback' })
  },
  {
    icon: QuestionMarkCircleIcon,
    typeComponent: 'button',
    text: 'Help',
    handlerClick: () => window.open('https://docs.enterprise.emailmeter.com/', '_blank')
  }
]
</script>

<template>
  <ModalComponent />
  <RouterView v-if="route.fullPath === '/login'"/>
  <div v-else-if="user" class="h-dvh w-screen !bg-gray-50" :key="isOpen">
    <PanelLayout
      class="h-full bg-gray-100"
      :close="handleOpen"
      :user="user"
      :header-items="sideBar.headerItem"
      :footer-items="sideBar.footerItem"
      @is-open="isOpen = true"
    />
    <div v-if="mainStore.isMobileOrTablet && isOpen" class="bg-gray-950/70 absolute top-0 bottom-0 left-[326px] right-0">
      <div class="h-10 w-10 p-2 fixed right-2 top-3 text-white cursor-pointer  rounded-lg hover:bg-white/10" @click="isOpen = false">
        <XMarkIcon class="h-6 w-6" />
      </div>
    </div>
    <div
      class="bg-gray-50 flex flex-col absolute right-0 justify-center flex-1 px-8 pt-8"
      :class="[mainStore.isMobileOrTablet ? 'left-0 top-16 bottom-0' : 'top-0 bottom-0 h-screen left-[264px] rounded-tl-xl border-l-1 overflow-y-scroll mt-[10px] border border-t-1 border-gray-200', isOpen ? 'hidden' : '']"
    >
      <RouterView v-if="!mounting" class="flex flex-col flex-1 mx-auto" />
      <div v-else class="flex flex-col flex-1 mx-auto justify-center">
        <SpinnerComponent />
      </div>
    </div>
  </div>
</template>
