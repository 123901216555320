<script setup lang="ts">
import { ref } from 'vue'
import { useToast } from 'vue-toastification'

import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useTeams from '@/modules/settings/composables/useTeams'
import useModal from '@/modules/shared/composable/useModal'
import SelectMultiple from '@/modules/shared/components/SelectMultiple'
import MODALS from '@/modules/shared/utils/modals_enum'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

const { onlyExistEmails: mailboxesEmails } = useMailboxes()
const { createTeams, teams, getTeams } = useTeams()
const toast = useToast()

const { activeModal, editTeamData, hideModal } = useModal()

const hasDataModal = activeModal.value === MODALS.updateTeams

const departament = ref(hasDataModal ? editTeamData.value.departament : '')

const members = ref(hasDataModal ? editTeamData.value.members.map((m) => { return m }) : [])
const options = ref(mailboxesEmails.value.map((m) => { return m }))

const isDisabled = () => !(members.value.length > 0 && departament.value !== '')

const ConfirmAddTeam = () => {
  if (!hasDataModal && teams.value.some((t) => t.name === departament.value)) {
    hideModal()
    toast.error('This team name already exists')
  } else {
    const newTeamsData = {
      name: departament.value,
      members: members.value
    }
    createTeams(newTeamsData).then(async () => {
      let title = ''
      await getTeams()
      hideModal()
      hasDataModal
        ? title = 'Team edited'
        : title = 'New team created'

      toast.success({
        component: ToastComponent,
        props: {
          title,
          messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
        },
        options: { icon: CheckCircleIcon }
      }, {
        timeout: 10000
      })
    })
  }
}
</script>

<template>
    <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">{{ hasDataModal ? 'Edit team' : 'Create new team' }}</p>
    <div class="p-6 border-b overflow-auto">
      <div class="pb-6">
        <label for="departament" class="block text-sm font-medium leading-5 text-gray-700">Team name</label>
        <div class="mt-1">
          <input
            id="departament"
            type="text"
            name="departament"
            v-model="departament"
            class="block w-full rounded-md border-0 py-2 px-3.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-5.25"
            :class="{'!bg-gray-50 !text-gray-500 !cursor-not-allowed': hasDataModal}"
            placeholder="Enter your team’s name"
            :disabled="hasDataModal"
          />
        </div>
      </div>
      <div>
        <SelectMultiple
          v-model:values="members"
          :options="options"
          placeholder="Select mailboxes"
          label="Mailboxes"
        />
      </div>
    </div>

    <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddTeam" />
</template>
