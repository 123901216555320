<!-- eslint-disable @typescript-eslint/no-non-null-assertion -->
<script lang="ts" setup>
import { ref, computed } from 'vue'
import { useToast } from 'vue-toastification'

import type { IModalData2 } from '@/modules/shared/interfaces/Modal'
import ButtonsModal from '@/modules/shared/components/ButtonsModal.vue'
import useModal from '@/modules/shared/composable/useModal'
import useContactGroups from '@/modules/settings/composables/useContactGroups'
import ToastComponent from '@/modules/shared/components/ToastComponent.vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'

const toast = useToast()
const { modalData, hideModal } = useModal()
const { renameContactGroup, contactGroups, getContactGroups } = useContactGroups()

const name = ref((modalData.value as IModalData2).name ?? '')
const groups = contactGroups.value.filter((cg) => cg.contact_group !== (modalData.value as IModalData2).name)

const checkName = computed(() => !!groups.find((cg) => cg.contact_group === name.value))

const isDisabled = () => (name.value.length === 0 || checkName.value)

const ConfirmAddContacts = () => {
  renameContactGroup({ name: (modalData.value as IModalData2).name, newName: name.value }).then(async () => {
    await getContactGroups()
    hideModal()
    toast.success({
      component: ToastComponent,
      props: {
        title: 'Contact group renamed',
        messenges: 'This change will be reflected in your dashboard tomorrow at 00:01 local time'
      },
      options: { icon: CheckCircleIcon }
    }, {
      timeout: 10000
    })
  })
}
</script>

<template>
  <p class="text-gray-900 font-semibold leading-8 text-lg p-6 border-b">Rename Contact Group</p>
  <div class="p-6 border-b overflow-auto rounded-lg">
    <label for="contact" class="block mb-1 text-sm font-medium leading-5 text-gray-700">Name</label>
    <input
      id="contact"
      type="text"
      name="contact"
      class="block w-full rounded-md border-0 py-2 px-3.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-5.25"
      placeholder="e.g Golden Clients"
      v-model="name"
    />
    <span v-if="checkName" class="text-red-400 block mt-1 text-left text-xs font-inter font-medium">
      Please check that the name is not repeated
    </span>
  </div>
  <ButtonsModal :isDisabled="isDisabled()" @submit="ConfirmAddContacts" />
</template>

<style scope></style>
