import { storeToRefs } from 'pinia'
import clientsApi from '@/api/clients-api'
import { DASHBOARD } from '@/api/url'
import type { IDashboard } from '@/modules/dashboard/interfaces/dashboard'
import { useDashboardStore } from '@/modules/dashboard/stores/dashboard'

const useDashboard = () => {
  const storeDasboard = useDashboardStore()
  const { dashboard } = storeToRefs(storeDasboard)

  const getDashboard = async ():Promise<IDashboard[]> => {
    const { data } = await clientsApi.get(DASHBOARD)
    if (data) storeDasboard.setDashboard(data)
    return data
  }

  const href = (e:{ preventDefault: ()=> void }) => {
    if (dashboard.value.length) {
      window.dataLayer = window.dataLayer || []
      window.open(dashboard.value[0].dashboard_url, '_blank')
      window.dataLayer.push({
        origin: 'enterprise_portal',
        event: 'openInLookerButton'
      })
    } else e.preventDefault()
  }

  return {
    // properties
    dashboard,

    // Method
    href,
    getDashboard
  }
}

export default useDashboard
