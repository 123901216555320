import clientsApi from '@/api/clients-api'

import type { IIBhs } from '@/modules/settings/interfaces/BusinessHours'
import { BUSINESSHOURS } from '@/api/url'
import { useMailboxesStore } from '@/modules/settings/stores/mailboxes'
import { weekdaysFormat } from '@/modules/settings/utils/parser/bhs'
import { ref } from 'vue'

const useBusinessHours = (mailbox:string[]) => {
  const storeMailboxes = useMailboxesStore()
  const enabled = ref(true)

  const getBusinessHours = async ():Promise<void> => {
    const { data } = await clientsApi.get(`${BUSINESSHOURS}${mailbox[0]}`)
    storeMailboxes.setMailboxBusinessHours(mailbox[0], weekdaysFormat(data))
    return data
  }

  const createBusinessHours = async (businessHours: IIBhs):Promise<void> => {
    businessHours.mailboxes?.forEach(async (mailbox:string) => {
      await clientsApi.post<IIBhs>(`${BUSINESSHOURS}${mailbox}`, {
        timezone: businessHours.timezone.replace(' ', '_'),
        business_hours: businessHours.business_hours
      })
      storeMailboxes.setMailboxBhsStatus(mailbox)
    })
  }

  const deleteBusinessHours = async (mailboxes:string[]):Promise<void> => {
    mailboxes.forEach(async (mailbox:string) => {
      await clientsApi.delete(`${BUSINESSHOURS}${mailbox}`)
    })
  }

  return {
    // properties
    enabled,

    // Method
    getBusinessHours,
    createBusinessHours,
    deleteBusinessHours
  }
}

export default useBusinessHours
